var LARAVEL_TOKEN = $('meta[name="csrf-token"]').attr('content');
var SUBSCRIBED = false;
function AJAX_SEND_CONTACT_FORM_DATA(/*HTML*/form,/*STRING*/text) {

    var dataForm = $(form).serializeArray().reduce(function (obj, item) {
        obj[item.name] = item.value;
        return obj;
    }, {});
    var data = {};
    data[GLOBAL_VAR_IS_STRING_DATA_SEND] = JSON.stringify(dataForm);

    $.ajax({
        url: GLOBAL_VAR_IS_METHOD_AJAX_SIDE_SITE + "/" +GLOBAL_VAR_IS_METHOD_SEND_CONTACT_FORM,
        type: "GET",
        data: data,
        headers: {
            'X-CSRF-TOKEN': LARAVEL_TOKEN
        },
        success: function (data) {
            if (data.status == GLOBAL_VAR_IS_STATUS_RESPONSE_OK) {
                if($("#ezwebPopupModal").length > 0){
                    $('#ezwebPopupModal').modal('show');
                    $("#myFormContact").find("input,textarea").each(function () {
                        this.value = "";
                    });
                    return false;
                }else{
                    alert("Mesajul a fost trimis!");
                    window.location.reload();
                }
               
            }
            else {
                alert("Ceva nu a mers bine!. Incercati inca o data")
            }
        },
        error: function (data) {

        }
    });

}

function AJAX_FORM_ADD_NEW_COMMENT(/*HTML*/form) {
    var dataForm = $(form).serializeArray().reduce(function (obj, item) {
        obj[item.name] = item.value;
        return obj;
    }, {});
    var data = {};
    data[GLOBAL_VAR_IS_STRING_DATA_SEND] = JSON.stringify(dataForm);
    if (typeof(Storage) !== "undefined") {
        localStorage.setItem("user_stored_comments_mail", dataForm['ezweb-comment-email']);
        localStorage.setItem("user_stored_comments_name", dataForm['ezweb-comment-name']);
    }

    $.ajax({
        url: GLOBAL_VAR_IS_METHOD_AJAX_SIDE_SITE + "/" +GLOBAL_VAR_IS_METHOD_ADD_NEW_COMMENT,
        type: "GET",
        data: data,
        headers: {
            'X-CSRF-TOKEN': LARAVEL_TOKEN
        },
        success: function (data) {
            if (data.status == GLOBAL_VAR_IS_STATUS_RESPONSE_OK) {
                if($("#ezwebPopupModal").length > 0){
                    $('#ezwebPopupModal').modal('show');
                    $("#myFormComments").find("textarea").each(function () {
                        this.value = "";
                    });

                }else{
                    alert("Mesajul a fost trimis catre aprobare!");
                    window.location.reload();
                }
            }
            else {
                alert("Mesajul nu a fost trimis!. Incercati inca o data")
            }
        },
        error: function (data) {

        }
    });
}

function AJAX_FORM_SUBSCRIBE(/*HTML*/elem) {
    var dataForm =  {
        'ezweb-subscribe-email':elem.value,
        'ezweb-subscribe-page': document.title
    }

    var data = {};
    data[GLOBAL_VAR_IS_STRING_DATA_SEND] = JSON.stringify(dataForm);

    $.ajax({
        url: GLOBAL_VAR_IS_METHOD_AJAX_SIDE_SITE + "/" +GLOBAL_VAR_IS_METHOD_SUBSCRIBE,
        type: "GET",
        data: data,
        headers: {
            'X-CSRF-TOKEN': LARAVEL_TOKEN
        },
        success: function (data) {
            if (data.status == GLOBAL_VAR_IS_STATUS_RESPONSE_OK) {
                alert("Te-ai abonat cu succes! Multumesc.")
                window.location.reload();
            }
            else {
                alert("Mesajul nu a fost trimis!. Incercati inca o data")
            }
        },
        error: function (data) {

        }
    });
}

function AJAX_PAGE_VISTED() {

    $.ajax({
        url: GLOBAL_VAR_IS_METHOD_AJAX_SIDE_SITE + "/" + GLOBAL_VAR_IS_METHOD_VISITED,
        type: "GET",
        data: {link : $('meta[name="page_link"]').attr('content'),title : document.title},
        headers: {
            'X-CSRF-TOKEN': LARAVEL_TOKEN
        },
        success: function (data) {

        },
        error: function (data) {

        }
    });
}

function AJAX_GET_SUBSCRIBE(value,callback) {

    if(value.includes("@")){
        var data = {};
        data[GLOBAL_VAR_IS_STRING_DATA_SEND] = value
        $.ajax({
            url: GLOBAL_VAR_IS_METHOD_AJAX_SIDE_SITE + "/" + GLOBAL_VAR_IS_METHOD_GET_SUBSCRIBE,
            type: "GET",
            data: data,
            headers: {
                'X-CSRF-TOKEN': LARAVEL_TOKEN
            },
            success: function (data) {
                if (data.status == GLOBAL_VAR_IS_STATUS_RESPONSE_OK) {
                    callback();
                }
                else {
                    alert("Sunteti deja abonat. Multumesc.");
                }
            },
            error: function (data) {

            }
        });
    }else {
        alert("Abonati-va cu o adresa de email valabila.");

    }
}

function userSaveInLocalStorage(){


    var getuser_comment_mail = localStorage.getItem("user_stored_comments_mail");
    var getuser_comment_name = localStorage.getItem("user_stored_comments_name");

    if (typeof getuser_comment_name === 'undefined' || getuser_comment_name == null) {
        $('[name="ezweb-comment-name"]').val("");
    }else{
        $('[name="ezweb-comment-name"]').val(getuser_comment_name);
    }

    if (typeof getuser_comment_mail === 'undefined' || getuser_comment_mail == null) {
        $('[name="ezweb-comment-email"]').val(getuser_comment_mail);
    }else{
        $('[name="ezweb-comment-email"]').val(getuser_comment_mail);
    }
}


$(document).ready(function(){
    $('.carousel').carousel({
        interval: 5000
    });

    if($('.input-subscribe-class').length>1){
        alert("Ne pare rau pe aceasta pagina exista mai mult de o casuta pentru subscribe. Sistemul este derutat. ")
    }else{
        if($('.input-subscribe-class').length == 1){
            $('.input-subscribe-class input[type="button"],.input-subscribe-class button').click(function(){
                debugger;
                if(!SUBSCRIBED){
                    AJAX_GET_SUBSCRIBE($('.input-subscribe-class input:first').get(0).value,function () {
                        AJAX_FORM_SUBSCRIBE($('.input-subscribe-class input:first').get(0));
                        SUBSCRIBED = true;
                    })

                }
            });
        }
    }
    userSaveInLocalStorage();

    if(PAGE_TERMS){
        $("#read_terms").click(function(){
            $("#post_comment_button").show();
            $(this).hide();
            if (typeof(Storage) !== "undefined") {
                localStorage.setItem("termsRead", "true");
            } else {
                alert('Local Storage nu e suportat de browserul dumneavoastra')
            }

        })

        if(localStorage.termsRead){
            $("#post_comment_button").show();
            $("#read_terms").hide();
        }
    }
    if($('#myFormComments').length > 0) {
        $('#myFormComments').validator().on('submit', function (e) {
            if (e.isDefaultPrevented()) {
                alert("Verificati datele introduse");
                return false;

            } else {
                AJAX_FORM_ADD_NEW_COMMENT(this);
                return false;
            }
        })
    };

    if($('#myFormContact').length > 0) {
        $('#myFormContact').validator().on('submit', function (e) {
            if (e.isDefaultPrevented()) {
                alert("Verificati datele introduse");
                return false;

            } else {
                AJAX_SEND_CONTACT_FORM_DATA(this);
                return false;
            }
        });
    };

    if($('.popup-gallery').length > 0){

        $(".popup-gallery").each(function(){
            var src = $(this).find('img')[0].src;
            $(this).find('.portfolio-box').attr('href',src);
        })

        $('.popup-gallery').magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Se incarca #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1]
            },
            image: {
                tError: '<a href="%url%">Imaginea #%curr%</a> nu poate fi incarcata.'
            }
        });
    };

})
